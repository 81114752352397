
import { Options, Vue } from 'vue-class-component'
// import type { ElForm, ElMessage } from 'element-plus'
import { ZMessage } from '@/utils/confirm'
import type { FormProps, FormInstance } from 'vant'
import { util } from '@/utils/util.js'

interface FormOption {
  phone: string | null
  code: string | null
  checked: boolean
  verification: string
}

@Options({
  watch: {
    form: {
      deep: true,
      handler: function () {
        const phoneIsValid = this.form.phone && /^1[3|4|5|6|7|8|9][0-9]{9}$/.test(this.form.phone)
        const codeIsValid = this.form.code && /^\d{4}$/.test(this.form.code)
        const checkedIsValid = this.form.checked
        if (phoneIsValid && codeIsValid && checkedIsValid) {
          this.loginButtonIsDisabled = false
        } else {
          this.loginButtonIsDisabled = true
        }
      }
    }
  }
  // beforeRouteEnter(to: any, from: any, next: any) {
  //   next((vm: any) => {
  //     console.log(`from`, from)
  //     vm.historyRoutefullPath = from.fullPath
  //   })
  // }
})
export default class Login extends Vue {
  private $store!: any
  public $http!: any
  public $dayjs!: any
  public $isMobile!: boolean

  public $refs!: any

  // const formRef = ref<FormInstance>()

  form: FormOption = {
    phone: null,
    code: null,
    checked: false,
    verification: null
  }

  // 验证滑块ref
  slideblockRef!: any

  // 显示验证码气泡
  verifyCodePopoverVisible = false

  // 禁用登录按钮
  loginButtonIsDisabled = true

  // 上级页面
  historyRoutefullPath?: string = ''

  // 60秒倒计时
  codeCount = 0

  // 倒计时显示
  countIsVisible = false

  src: any = null

  timer: any = null

  codeImage: string = null
  generatorId: string = null

  rules = {
    phone: [
      { required: true, message: '手机号码不能为空', trigger: 'change' },
      { pattern: /^1[3|4|5|6|7|8|9][0-9]{9}$/, message: '手机号码格式不正确' }
    ],
    code: [
      { required: true, message: '验证码不能为空', trigger: 'change' },
      { pattern: /^\d{4}$/, message: '验证码格式不正确' }
    ],
    verification: [
      { required: true, message: '验证码不能为空', trigger: 'change' },
      { pattern: /^\d{4}$/, message: '验证码格式不正确' }
    ]
  }

  mounted() {
    // this.slideblockRef = this.$refs.slideblock
    // console.log(`😃 😃 😃 isMobile 😃 😃 😃 `, this.$isMobile)
    this.historyRoutefullPath = this.$store.state.redirectUrl
    // console.log(`😃 😃 😃 historyRoutefullPath 😃 😃 😃 `, this.historyRoutefullPath)
    this.getVerificationImg()
  }

  // 点击获取验证码
  handleVerifyClick() {
    if (!this.$isMobile) {
      this.$refs['formRef'].validateField('phone', (valid: any) => {
        if (!valid) {
          this.$refs['formRef'].validateField('verification', (errorMsg: any) => {
            if (!errorMsg) {
              this.getVerifyCode()
            }
          })
        }
      })
    } else {
      this.$refs['formRef'].validate(['phone', 'verification']).then(() => {
        this.getVerifyCode()
      })
    }
  }

  // 获取验证码
  getVerifyCode() {
    let data = {
      phone: this.form.phone,
      generatorId: this.generatorId,
      code: this.form.verification
    }
    this.$http.auth
      .smsSend(data)
      .then((res: any) => {
        const TIME_COUNT = 60
        if (!this.timer) {
          this.codeCount = TIME_COUNT
          this.countIsVisible = true
          this.timer = setInterval(() => {
            if (this.codeCount > 0 && this.codeCount <= TIME_COUNT) {
              this.codeCount--
            } else {
              this.countIsVisible = false
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      })
      .catch((res: any) => {
        this.getVerificationImg()
        this.form.verification = null
      })
  }

  // 验证码登录
  handleSubmit() {
    if (this.$isMobile) {
      this.$refs['formRef'].validate().then(() => {
        this.handleLoginClick()
      })
    } else {
      this.$refs['formRef'].validate((valid: any) => {
        if (valid) {
          this.handleLoginClick()
        }
      })
    }
  }
  handleLoginClick() {
    this.$http.auth.smsLogin({ phone: this.form.phone, smsCode: Number(this.form.code) }).then((res: any) => {
      this.$store.dispatch('setAuthorization', res.token)
      if (this.historyRoutefullPath) {
        this.$router.push(this.historyRoutefullPath)
      } else {
        this.$router.push({
          name: 'Home'
        })
      }
      this.$store.dispatch('setRedirectUrl', null)
    })
  }

  // 获取图形验证码
  getVerificationImg() {
    this.$http.auth.getVerificationImg().then((res) => {
      this.codeImage = res.codeImage
      this.generatorId = res.generatorId
    })
  }
  // 微信登录
  toWxLogin() {
    if (this.form.checked === false) {
      ZMessage({
        message: `请先勾选同意用户协议和隐私政策`,
        type: 'error'
      })
      return false
    }
    // Unix 时间戳 (毫秒) dayjs().valueOf()
    // Unix 时间戳 (秒)  dayjs().unix()
    const appidPC = `wx89da3fcd18499d1b`
    const appidMobile = `wx73af977f8da80502`
    // console.log(`😃 😃 😃 location 😃 😃 😃`, location.origin)
    let redirect_uri = ''
    if (this.historyRoutefullPath) {
      // redirect_uri = `https://recruiting-gw-test.zt-express.com${this.historyRoutefullPath}`
      redirect_uri = `${location.origin}${this.historyRoutefullPath}`
    } else {
      // redirect_uri = `https://hr.zto.com`
      redirect_uri = location.origin
    }
    console.log(`redirect_uri`, redirect_uri)
    let encryptValue = this.$dayjs(new Date()).valueOf().toString()
    let state = util.encryptDes(encryptValue, 'jy2020@#$%^&')
    if (!this.$isMobile) {
      this.src = `https://open.weixin.qq.com/connect/qrconnect?appid=${appidPC}&redirect_uri=${encodeURIComponent(
        redirect_uri
      )}&response_type=code&scope=snsapi_login&connect_redirect=1&state=${state}#wechat_redirect`
    } else {
      window.open(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appidMobile}&redirect_uri=${encodeURIComponent(
          redirect_uri
        )}&response_type=code&scope=snsapi_userinfo&connect_redirect=1&state=${state}#wechat_redirect`
      )
    }
  }
}
